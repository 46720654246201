import { Outlet } from 'react-router-dom';

import Container from '@mui/material/Container';

import AuthProvider from 'hooks/useAuth';

const CommonLayout = () => {
	return (
		<AuthProvider>
			<Container
				maxWidth="md"
				className="common-layout"
				sx={{
					alignItems: 'flex-start',
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'center',
					height: '100%',
				}}
			>
				<Outlet />
			</Container>
		</AuthProvider>
	);
};

export default CommonLayout;
