export default function CardContent() {
	return {
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding:
						'var(--spacing-vertical) var(--spacing-horizontal)',
					'&:last-child': {
						padding:
							'var(--spacing-vertical) var(--spacing-horizontal)',
					},
				},
			},
		},
	};
}
