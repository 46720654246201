import Typography from '@mui/material/Typography';

import MenuItems from './MenuItems';

const NavGroup = ({ title, icon, ...props }) => {
	const Icon = icon;
	return (
		<div className="nav-group">
			{(Boolean(title) || Icon) && (
				<Typography
					variant="h5"
					gutterBottom
					sx={{ display: 'flex', alignItems: 'center', gap: 1 }}
				>
					{Icon && <Icon />} {title}
				</Typography>
			)}

			<MenuItems {...props} />
		</div>
	);
};

export default NavGroup;
