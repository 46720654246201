import MainLayout from 'layout/MainLayout';
import LinkButton from 'components/LinkButton';

function ErrorPage() {
	return (
		<div style={{ textAlign: 'center' }}>
			<h1 style={{ fontSize: '15vw', margin: '5vh auto 30px' }}>404</h1>

			<h3>ขออภัย ไม่พบหน้าที่คุณต้องการเรียกดู</h3>
			<LinkButton variant="contained" to="/" size="large" color="primary">
				กลับหน้าหลัก
			</LinkButton>
		</div>
	);
}

const Error = {
	path: '/',
	element: <MainLayout />,
	children: [
		{
			path: '*',
			element: <ErrorPage />,
		},
	],
};

export default Error;
