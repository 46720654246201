import { forwardRef } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Button from '@mui/material/Button';

const LinkButton = ({ to, children, ...props }) => {
	const location = useLocation();

	if (typeof to === 'function') {
		to = to(location);
	}

	return (
		<Button
			component={forwardRef((props, ref) => (
				<Link ref={ref} {...props} to={to} />
			))}
			type="button"
			variant="contained"
			color="secondary"
			{...props}
		>
			{children}
		</Button>
	);
};

export default LinkButton;
