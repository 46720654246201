export default function InputBase() {
	return {
		MuiInputBase: {
			defaultProps: {
				size: 'small',
			},
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.size === 'normal' && {
						'& .MuiInputBase-input': {
							paddingBottom: 10,
							paddingTop: 10,
						},
					}),
					...(ownerState.disabled && {
						'& fieldset': {
							border: 'none',
							borderBottomWidth: 1,
							borderBottomStyle: 'solid',
							borderRadius: 0,
						},
					}),
				}),
			},
		},
	};
}
