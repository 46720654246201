import { lazy } from 'react';

import CommonLayout from 'layout/CommonLayout';

import Loadable from 'components/Loadable';

const Login = Loadable(lazy(() => import('modules/auth/login')));
const Register = Loadable(lazy(() => import('modules/auth/register')));

const Routes = {
	path: '/auth',
	element: <CommonLayout />,
	children: [
		{
			path: 'login',
			element: <Login />,
		},
		{
			path: 'register',
			element: <Register />,
		},
	],
};

export default Routes;
