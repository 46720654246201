import { useCallback, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import HomeIcon from '@mui/icons-material/Home';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import { useAuth } from 'hooks/useAuth';

import { useQueryClient } from 'hooks/useQuery';

import { getFileUrl } from 'services/file';

const Logo = ({ path, title }) => {
	return path ? (
		<img src={getFileUrl(path)} alt={title} />
	) : (
		<HomeIcon alt={title} />
	);
};

const Title = ({ children, ...props }) => (
	<Typography
		{...props}
		sx={{
			display: '-webkit-box',
			overflow: 'hidden',
			WebkitBoxOrient: 'vertical',
			WebkitLineClamp: 2,
		}}
	>
		{children}
	</Typography>
);

const Profile = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const navigate = useNavigate();

	const { user, logout, switchSite } = useAuth();

	const queryClient = useQueryClient();

	const handleProfileClick = useCallback(() => {
		navigate('./settings/account');
	}, [navigate]);

	const handleLogoutClick = useCallback(() => {
		logout();
	}, [logout]);

	const handleChangeSite = useCallback(
		(id) => {
			switchSite(id, {
				onSuccess: () => {
					queryClient.refetchQueries({ type: 'active' });
				},
			});
		},
		[switchSite, queryClient],
	);

	const handleToggle = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	return user ? (
		<Box sx={{ flexShrink: 0 }}>
			<IconButton
				sx={{
					bgcolor: open ? 'primary.lighter' : 'transparent',
					color: open ? 'primary.main' : 'secondary.main',
					'&:hover': {
						bgcolor: open ? 'primary.lighter' : 'secondary.lighter',
					},
				}}
				onClick={handleToggle}
			>
				<Avatar alt="User profile" sx={{ width: 28, height: 28 }}>
					<AccountCircleIcon />
				</Avatar>
			</IconButton>

			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'right',
				}}
				transformOrigin={{
					vertical: -8,
					horizontal: 'left',
				}}
				sx={{ zIndex: 900 }}
			>
				<Card
					sx={{
						width: 290,
					}}
				>
					{user.sites?.length > 1 && (
						<>
							<List
								subheader={
									<ListSubheader component="div">
										เลือกสาขา
									</ListSubheader>
								}
								sx={{ p: 0 }}
							>
								{user.sites.map((item, index) => (
									<ListItemButton
										key={`site-${index}`}
										onClick={() => {
											handleChangeSite(item.id);
										}}
										selected={item.id === user.siteId}
										sx={{
											color: item.selected
												? 'primary.main'
												: 'secondary.main',
										}}
									>
										<ListItemAvatar>
											<Avatar
												sx={{
													backgroundColor:
														'transparent',
													color: 'inherit',
												}}
											>
												<Logo
													path={item.logo}
													title={item.title}
												/>
											</Avatar>
										</ListItemAvatar>
										<ListItemText
											primary={
												<Title>{item.title}</Title>
											}
										/>
									</ListItemButton>
								))}
							</List>

							<Divider />
						</>
					)}

					<List
						subheader={
							<ListSubheader
								component="div"
								id="nested-list-subheader"
							>
								บัญชีผู้ใช้งาน
							</ListSubheader>
						}
						sx={{ p: 0 }}
					>
						<ListItemButton onClick={handleProfileClick}>
							<ListItemAvatar>
								<Avatar alt="User Profile">
									<AccountCircleIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText
								primary={
									<Typography variant="h6">
										{user.name}
									</Typography>
								}
								secondary={user?.role.name}
							/>
						</ListItemButton>

						<ListItemButton onClick={handleLogoutClick}>
							<ListItemAvatar>
								<Avatar>
									<ExitToAppIcon />
								</Avatar>
							</ListItemAvatar>
							<ListItemText primary="ออกจากระบบ" />
						</ListItemButton>
					</List>
				</Card>
			</Popover>
		</Box>
	) : null;
};

export default Profile;
