import PropTypes from 'prop-types';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import MenuOpenIcon from '@mui/icons-material/MenuOpen';

import Logo from './Logo';
import Title from './Title';
import Notification from './Notification';
import Profile from './Profile';

const Header = ({ title, logo, open, onToggle, mobile }) => {
	return (
		<header className="main-header">
			<div className="header-logo">
				{Boolean(logo) && <Logo value={logo} />}
			</div>

			<div className="header-content">
				{!mobile && <Title value={title} />}
				<Notification />
				<Profile />
				{mobile && (
					<IconButton
						className="menu-button"
						aria-label="Toggle Menu"
						onClick={onToggle}
					>
						{open ? <MenuOpenIcon /> : <MenuIcon />}
					</IconButton>
				)}
			</div>
		</header>
	);
};

Header.propTypes = {
	title: PropTypes.string,
	logo: PropTypes.string,
	open: PropTypes.bool,
	onToggle: PropTypes.func,
	mobile: PropTypes.bool,
};

export default Header;
