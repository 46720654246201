import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

import { styled } from '@mui/material/styles';

const ListItem = styled(ListItemButton)(() => ({
	borderRadius: 8,
	marginBottom: 2,
	padding: 8,
	gap: 8,
}));

const NavItem = (props) => {
	let itemTarget = props.target ? '_blank' : undefined;

	let listItemProps = {
		component: forwardRef((linkProps, ref) => (
			<Link ref={ref} {...linkProps} to={props.url} target={itemTarget} />
		)),
	};

	if (props?.external) {
		listItemProps = { component: 'a', href: props.url, target: itemTarget };
	}

	const Icon = props.icon;
	const itemIcon = props.icon ? <Icon fontSize="small" /> : false;

	const textColor = 'secondary.main';
	const selectedColor = 'primary.main';

	return (
		<ListItem
			{...listItemProps}
			disabled={props.disabled}
			selected={props.selected}
		>
			{itemIcon && (
				<ListItemIcon
					sx={{
						color: props.selected ? selectedColor : textColor,
						minWidth: 0,
					}}
				>
					{itemIcon}
				</ListItemIcon>
			)}

			<ListItemText
				primary={
					<Typography
						variant="span"
						sx={{
							color: props.selected ? selectedColor : textColor,
							fontWeight: props.selected ? 500 : 400,
						}}
					>
						{props.title}
					</Typography>
				}
				sx={{
					margin: 0,
				}}
			/>
		</ListItem>
	);
};

export default NavItem;
