export default function Autocomplete() {
	return {
		MuiAutocomplete: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState?.fullWidth === false && {
						maxWidth: 450,
						width: '100%',
					}),
					'& .MuiOutlinedInput-root.MuiInputBase-sizeNormal': {
						paddingBottom: 0,
						paddingTop: 0,
					},
					'& .MuiOutlinedInput-root.MuiInputBase-sizeNormal .MuiAutocomplete-input':
						{
							paddingBottom: 10,
							paddingTop: 10,
						},
				}),
			},
		},
	};
}
