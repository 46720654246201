import dayjs from 'dayjs';

export function toDateTime(dateTime) {
	return dayjs(dateTime);
}

export function formatDateTime(value, format) {
	if (!format) {
		format = 'DD/MM/BBBB HH:mm';
	}

	return toDateTime(value).format(format);
}

export function formatDate(value, format) {
	if (!format) {
		format = 'DD/MM/BBBB';
	}

	return formatDateTime(value, format);
}
