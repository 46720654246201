export default function TextField() {
	return {
		MuiTextField: {
			defaultProps: {
				size: 'small',
			},
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.fullWidth === false && {
						maxWidth: 450,
						width: '100%',
					}),
				}),
			},
		},
	};
}
