import ThemeCustomization from 'theme';

import QueryProvider from 'hooks/useQuery';

import Routes from 'routes';

const App = () => {
	return (
		<ThemeCustomization>
			<QueryProvider>
				<Routes />
			</QueryProvider>
		</ThemeCustomization>
	);
};

export default App;
