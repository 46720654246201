import { useMemo } from 'react';

import { useAuth } from 'hooks/useAuth';

import { DesktopMenu, MobileMenu } from 'menu';

const filterMenuItems = (role, menu) => {
	menu.forEach((item, index) => {
		if (item.roles && !item.roles.includes(role.id)) {
			item.visible = false;
		}

		if (item.level && role.level < item.level) {
			item.visible = false;
		}

		if (item.visible === false) {
			delete menu[index];
		}

		if (item.children) {
			filterMenuItems(role, item.children);
		}
	});
};

function useMenu(name = '') {
	const { user } = useAuth();

	const items = useMemo(() => {
		let _items = [];
		if (user?.role) {
			const menu = [
				...(name?.toLowerCase() === 'mobile'
					? MobileMenu
					: DesktopMenu),
			];

			filterMenuItems(user.role, menu);

			_items = menu;
		}

		return _items;
	}, [name, user?.role]);

	return { name, items };
}

export default useMenu;
