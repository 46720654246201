import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import ButtonBase from '@mui/material/ButtonBase';

import { APP_DEFAULT_PATH } from 'config';

import { getFileUrl } from 'services/file';

const Logo = ({ value, to, ...props }) => {
	value = getFileUrl(value);

	props.sx = Object.assign({}, props.sx || {}, {
		overflow: 'hidden',
	});

	return (
		<ButtonBase
			disableRipple
			component={Link}
			to={to ? to : APP_DEFAULT_PATH}
			{...props}
		>
			{value && <img src={value} alt="logo" />}
		</ButtonBase>
	);
};

Logo.propTypes = {
	logo: PropTypes.string,
	to: PropTypes.string,
	title: PropTypes.string,
};

export default Logo;
