import NavItem from './NavItem';
import NavCollapse from './NavCollapse';

import { useNavigation } from 'hooks/useNavigation';

export default function MenuItems(props) {
	const { selected } = useNavigation();

	return props.children?.map((child, index) => {
		child.id = child.id || `${props.id}${index}`;

		child.selected = selected[child.type] === child.id;

		switch (child.type) {
			case 'collapse':
				return <NavCollapse key={`${child.id}`} {...child} />;
			default:
				return <NavItem key={`${child.id}`} {...child} />;
		}
	});
}
