import { useState } from 'react';

import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';

import NotificationsIcon from '@mui/icons-material/Notifications';
import InfoIcon from '@mui/icons-material/Info';
import { formatDate } from 'utils/dateTime';

const Notification = () => {
	const [anchorEl, setAnchorEl] = useState(null);

	const handleToggle = (event) => {
		setAnchorEl(anchorEl ? null : event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);

	const items = [];

	return (
		<Box sx={{ flexShrink: 0 }}>
			<IconButton
				sx={{
					bgcolor: open ? 'primary.lighter' : 'transparent',
					color: open ? 'primary.main' : 'secondary.main',
					'&:hover': {
						bgcolor: open ? 'primary.lighter' : 'secondary.lighter',
					},
				}}
				onClick={handleToggle}
			>
				<Badge color="primary">
					<NotificationsIcon />
				</Badge>
			</IconButton>
			<Popover
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: 'bottom',
					horizontal: 'left',
				}}
				transformOrigin={{
					vertical: -8,
					horizontal: 'left',
				}}
				sx={{ zIndex: 900 }}
			>
				<Card
					sx={{
						minWidth: 200,
					}}
				>
					<List
						subheader={
							<ListSubheader component="div">
								<strong>แจ้งเตือน</strong>
							</ListSubheader>
						}
						sx={{
							p: 0,
							'& .MuiListItemButton-root': {
								py: 0.5,
							},
						}}
					>
						{items.length === 0 && (
							<ListItemButton>
								<ListItemText
									primary={
										<Typography>
											ยังไม่มีรายการแจ้งเตือน
										</Typography>
									}
								/>
							</ListItemButton>
						)}
						{items.length > 0 &&
							items.map((item) => (
								<ListItemButton>
									<ListItemAvatar>
										<InfoIcon color="info" />
									</ListItemAvatar>
									<ListItemText
										primary={
											<Typography variant="strong">
												{item.text}
											</Typography>
										}
										secondary={formatDate(item.date)}
									/>
								</ListItemButton>
							))}
					</List>
				</Card>
			</Popover>
		</Box>
	);
};

export default Notification;
