import MainLayout from 'layout/MainLayout';

import { getRoutes } from './router';

const routes = [
	{
		path: 'sales',
	},
	{
		path: 'sales/order',
		children: [
			{
				path: 'sales/orders/bill',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/orders/tax-renew',
			},
			{
				path: 'sales/orders/tax-return',
			},
			{
				path: 'sales/orders/checkout',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/orders/delivery',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/orders/deposit',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/orders/payment-notices',
				context: ['edit'],
				list: true,
			},
			{
				path: 'sales/orders/customers',
				context: ['new', 'edit'],
				list: true,
			},
		],
	},
	{
		path: 'sales/insurance',
		children: [
			{
				path: 'sales/insurance/policy',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/insurance/premium',
				context: ['edit'],
				list: true,
			},
			{
				path: 'sales/insurance/renewal-notices',
				context: ['edit'],
				list: true,
			},
		],
	},
	{
		path: 'sales/receipts',
		children: [
			{
				path: 'sales/receipts/receivable',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/receipts/receipt',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/receipts/tax-receive',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'sales/receipts/tax-return',
				context: ['new', 'edit'],
				list: true,
			},
		],
	},
];

const Sales = {
	path: '/',
	element: <MainLayout />,
	children: getRoutes(routes, true),
};

export default Sales;
