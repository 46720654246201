import PointOfSaleIcon from '@mui/icons-material/PointOfSale';
import ListAltIcon from '@mui/icons-material/ListAlt';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import AssignmentReturnIcon from '@mui/icons-material/AssignmentReturn';
import ReceiptIcon from '@mui/icons-material/Receipt';
import CreditCardIcon from '@mui/icons-material/CreditCard';

import NoCrashIcon from '@mui/icons-material/NoCrash';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import EmojiTransportationIcon from '@mui/icons-material/EmojiTransportation';
import FactCheckIcon from '@mui/icons-material/FactCheck';

import NoteAltIcon from '@mui/icons-material/NoteAlt';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import AlarmIcon from '@mui/icons-material/Alarm';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import ContactEmergencyIcon from '@mui/icons-material/ContactEmergency';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';

const icons = {
	sale: PointOfSaleIcon,
	list: ListAltIcon,
	add: NoteAddIcon,
	receipt: ReceiptLongIcon,
	return: AssignmentReturnIcon,
	paper: ReceiptIcon,
	send: ForwardToInboxIcon,
	checkout: FactCheckIcon,
	bank: AccountBalanceIcon,
	card: CreditCardIcon,
	customer: ContactPhoneIcon,
	carTax: EmojiTransportationIcon,
	backward: NoCrashIcon,
	note: NoteAltIcon,
	car: DirectionsCarIcon,
	health: HealthAndSafetyIcon,
	contact: ContactEmergencyIcon,
	act: WorkspacePremiumIcon,
	premium: SwapHorizIcon,
	alarm: AlarmIcon,
	receivable: AssignmentIndIcon,
};

const Sales = {
	type: 'group',
	title: 'งานขาย',
	children: [
		{
			type: 'collapse',
			title: 'ขาย',
			icon: icons.sale,
			url: '/sales/orders',
			children: [
				{
					type: 'item',
					title: 'บิลขาย',
					icon: icons.list,
					url: '/sales/orders/bill',
				},
				{
					type: 'item',
					title: 'ส่งงานต่อภาษี',
					icon: icons.carTax,
					url: '/sales/orders/tax-renew',
				},
				{
					type: 'item',
					title: 'รับงานเข้า',
					icon: icons.backward,
					url: '/sales/orders/tax-return',
				},
				{
					type: 'item',
					title: 'จ่ายงาน',
					icon: icons.checkout,
					url: '/sales/orders/checkout',
				},
				{
					type: 'item',
					title: 'ไปรษณีย์',
					icon: icons.send,
					url: '/sales/orders/delivery',
				},
				{
					type: 'item',
					title: 'เงินฝากประจำวัน',
					icon: icons.bank,
					url: '/sales/orders/deposit',
				},
				{
					type: 'item',
					title: 'ลูกหนี้',
					icon: icons.contact,
					url: '/sales/orders/payment-notices',
				},
				{
					type: 'item',
					title: 'ข้อมูลลูกค้า',
					icon: icons.customer,
					url: '/sales/orders/customers',
				},
			],
		},
		{
			type: 'collapse',
			title: 'ประกันและพรบ.',
			icon: icons.health,
			url: '/sales/insurance',
			children: [
				{
					type: 'item',
					title: 'ข้อมูลกรมธรรม์',
					icon: icons.note,
					url: '/sales/insurance/policy',
				},
				{
					type: 'item',
					title: 'นำส่งเบี้ย',
					icon: icons.premium,
					url: '/sales/insurance/premium',
					level: 10,
				},
				{
					type: 'item',
					title: 'เตือนต่ออายุ',
					icon: icons.alarm,
					url: '/sales/insurance/renewal-notices',
				},
			],
		},
		{
			type: 'collapse',
			title: 'รับเงิน',
			icon: icons.receipt,
			url: '/sales/receipts',
			children: [
				{
					type: 'item',
					title: 'รับเงินลูกหนี้',
					icon: icons.receivable,
					url: '/sales/receipts/receivable',
				},
				{
					type: 'item',
					title: 'เก็บภาษีขนส่งเพิ่ม',
					icon: icons.add,
					url: '/sales/receipts/tax-receive',
				},
				{
					type: 'item',
					title: 'เงินคืนภาษีขนส่ง',
					icon: icons.return,
					url: '/sales/receipts/tax-return',
				},
				{
					type: 'item',
					title: 'รับอื่นๆ',
					icon: icons.paper,
					url: '/sales/receipts/receipt',
				},
			],
		},
	],
};

export default Sales;
