import { lazy } from 'react';

import CommonLayout from 'layout/CommonLayout';

import Loadable from 'components/Loadable';

const Index = Loadable(lazy(() => import('modules/cs/index')));
const Token = Loadable(lazy(() => import('modules/cs/token')));
const Order = Loadable(lazy(() => import('modules/cs/order')));
const Payment = Loadable(lazy(() => import('modules/cs/payment')));

const Public = {
	path: '/cs',
	element: <CommonLayout />,
	children: [
		{
			path: 'token/:code',
			element: <Token />,
		},
		{
			path: 'order',
			element: <Order />,
		},
		{
			path: 'payment',
			element: <Payment />,
		},
		{
			path: ':customerId?/:orderId?',
			element: <Index />,
		},
	],
};

export default Public;
