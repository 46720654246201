import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

function Title({ value, ...props }) {
	return (
		<Typography variant="h5" {...props}>
			<Chip variant="outlined" color="primary" label={value} />
		</Typography>
	);
}

export default Title;
