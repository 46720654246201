import ReceiptIcon from '@mui/icons-material/Receipt';
import DescriptionIcon from '@mui/icons-material/Description';
import PaidIcon from '@mui/icons-material/Paid';

const icons = {
	receipt: ReceiptIcon,
	expense: PaidIcon,
	report: DescriptionIcon,
};

const Reports = {
	type: 'group',
	title: 'รายงาน',
	level: 10,
	children: [
		{
			type: 'collapse',
			title: 'งานขาย',
			icon: icons.receipt,
			url: '/reports/sales',
			children: [
				{
					type: 'item',
					title: 'รายงานขาย',
					icon: icons.report,
					url: '/reports/sales/orders',
				},
				{
					type: 'item',
					title: 'รายงานรับเงิน',
					icon: icons.report,
					url: '/reports/sales/receipts',
				},
				{
					type: 'item',
					title: 'รายงานเช็ครับ',
					icon: icons.report,
					url: '/reports/sales/cheques',
				},
				{
					type: 'item',
					title: 'รายงานเงินโอน',
					icon: icons.report,
					url: '/reports/sales/transfers',
				},
				{
					type: 'item',
					title: 'ส่งไปรษณีย์',
					icon: icons.report,
					url: '/reports/sales/delivery',
				},
				{
					type: 'item',
					title: 'ฝากเงินธนาคาร',
					icon: icons.report,
					url: '/reports/sales/deposit',
				},
				{
					type: 'item',
					title: 'ภาษีขนส่ง',
					icon: icons.report,
					url: '/reports/sales/vehicle-tax',
				},
				{
					type: 'item',
					title: 'ลูกหนี้',
					icon: icons.report,
					url: '/reports/sales/receivable',
				},
				{
					type: 'item',
					title: 'ประกัน',
					icon: icons.report,
					url: '/reports/sales/insurance',
				},
				{
					type: 'item',
					title: 'ส่งเบี้ย',
					icon: icons.report,
					url: '/reports/sales/insurance-premium',
				},
			],
		},
		{
			type: 'collapse',
			title: 'ค่าใช้จ่าย',
			icon: icons.expense,
			url: '/reports/expenses',
			children: [
				{
					type: 'item',
					title: 'ค่าใช้จ่าย',
					icon: icons.report,
					url: '/reports/expenses/expenditures',
				},
				{
					type: 'item',
					title: 'รายการซื้อ',
					icon: icons.report,
					url: '/reports/expenses/purchases',
				},
				{
					type: 'item',
					title: 'สต็อกสินค้า',
					icon: icons.report,
					url: '/reports/expenses/inventory',
				},
			],
		},
	],
};

export default Reports;
