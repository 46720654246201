import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';

import { useNavigation } from 'hooks/useNavigation';

import NavGroup from './NavGroup';

const Navigation = ({ menu }) => {
	const { selected, setSelected } = useNavigation();

	const { pathname } = useLocation();

	const selectCurrent = useCallback(
		(item) => {
			if (pathname.includes(item.url)) {
				if (item.parent) {
					selected[item.parent.type] = item.parent.id;
				} else {
					Object.keys(selected).forEach(
						(key) => delete selected[key],
					);
				}

				selected[item.type] = item.id;
			}

			if (item.children) {
				item.children.forEach((child, index) => {
					child.id = `${item.id}${index}`;
					if (item.title) {
						child.parent = { type: item.type, id: item.id };
					}
					selectCurrent(child);
				});
			}

			setSelected({ ...selected });
		}, // eslint-disable-next-line
		[pathname, setSelected],
	);

	useEffect(() => {
		menu?.items.forEach((item, index) => {
			item.id = index.toString();
			selectCurrent(item);
		});
	}, [menu?.items, selectCurrent]);

	const menuItems = menu.items.map((item, index) => {
		item.id = item.id || index.toString();
		return <NavGroup key={item.id} {...item} />;
	});

	return <nav className="main-nav">{menuItems}</nav>;
};

export default Navigation;
