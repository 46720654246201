/**
 * External dependencies
 */
import { alpha } from '@mui/material/styles';

/**
 * Returns a shadow options object.
 *
 * @param {object} palette A palette object.
 * @return {object} A shadow options object.
 */
const Shadows = (palette) => ({
	button: `0 2px #0000000b`,
	text: `0 -1px 0 rgb(0 0 0 / 12%)`,
	z1: `0px 8px 24px  ${alpha(palette.secondary.darker, 0.08)}`,
	z2: `0px 2px 8px  ${alpha(palette.secondary.darker, 0.08)}`,
	primary: `0 0 0 2px ${alpha(palette.primary.main, 0.1)}`,
	secondary: `0 0 0 2px ${alpha(palette.secondary.main, 0.2)}`,
	error: `0 0 0 2px ${alpha(palette.error.main, 0.2)}`,
	warning: `0 0 0 2px ${alpha(palette.warning.main, 0.2)}`,
	info: `0 0 0 2px ${alpha(palette.info.main, 0.2)}`,
	success: `0 0 0 2px ${alpha(palette.success.main, 0.2)}`,
	grey: `0 0 0 2px ${alpha(palette.secondary.main, 0.2)}`,
	primaryButton: `0 14px 12px ${alpha(palette.primary.main, 0.2)}`,
	secondaryButton: `0 14px 12px ${alpha(palette.secondary.main, 0.2)}`,
	errorButton: `0 14px 12px ${alpha(palette.error.main, 0.2)}`,
	warningButton: `0 14px 12px ${alpha(palette.warning.main, 0.2)}`,
	infoButton: `0 14px 12px ${alpha(palette.info.main, 0.2)}`,
	successButton: `0 14px 12px ${alpha(palette.success.main, 0.2)}`,
	greyButton: `0 14px 12px ${alpha(palette.secondary.main, 0.2)}`,
});

export default Shadows;
