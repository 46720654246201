import {
	getItem,
	getSession,
	setItem,
	setSession,
	removeItem,
	removeSession,
} from './storage';

export const getLocalAccessToken = () => {
	return getSession('accessToken');
};

export const setLocalAccessToken = (value) => {
	setSession('accessToken', value);
};

export const removeLocalAccessToken = () => {
	removeSession('accessToken');
};

export const getTokenExpirationDate = () => {
	const expirationTime = getItem('tokenExp');

	return expirationTime ? new Date(Number(expirationTime)) : null;
};

export const setTokenExpiationDate = (value = 0) => {
	const date = new Date();

	date.setSeconds(date.getSeconds() + value);

	return setItem('tokenExp', date.getTime());
};

export const getLocalRefreshToken = () => {
	const saveAuth = isLocalAuthTokenEnabled();
	if (saveAuth) {
		return getItem('refreshToken');
	} else {
		return getSession('refreshToken');
	}
};

export const isLocalAuthTokenEnabled = () => {
	return getItem('saveAuth') === '1';
};

export const setLocalAuthTokenEnabled = (value) => {
	setItem('saveAuth', value ? '1' : '0');
};

export const setLocalRefreshToken = (value, saveAuth) => {
	if (typeof saveAuth === 'undefined') {
		saveAuth = isLocalAuthTokenEnabled();
	}

	if (saveAuth) {
		setItem('refreshToken', value);
	} else {
		removeItem('refreshToken');
		setSession('refreshToken', value);
	}
};

export const removeLocalRefreshToken = () => {
	removeItem('refreshToken');
};

export const saveAuthToken = (
	{ accessToken, refreshToken, expiresIn },
	saveAuth,
) => {
	if (typeof saveAuth !== 'undefined') {
		setLocalAuthTokenEnabled(saveAuth);
	}

	setLocalAccessToken(accessToken);
	setLocalRefreshToken(refreshToken, saveAuth);
	setTokenExpiationDate(expiresIn);
};

export const clearAuthenToken = () => {
	removeLocalAccessToken();
	removeLocalRefreshToken();
};
