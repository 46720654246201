/**
 * Internal dependencies
 */
import Colors from './colors';

/**
 * Returns a palette object.
 *
 * @param {string} mode A color scheme mode.
 * @return {object} A palette object.
 */
const Palette = (mode) => {
	const colors = Colors();

	const black = '#121212';
	const white = '#fff';

	const isDarkMode = mode === 'dark';

	return {
		mode,
		common: {
			black: black,
			white: white,
		},
		...colors,
		background: {
			default: isDarkMode ? black : colors.secondary.lighter,
			paper: isDarkMode ? colors.secondary.darker : white,
		},
		text: {
			primary:
				mode === 'dark'
					? colors.secondary.light
					: colors.secondary.dark,
			secondary: mode === colors.secondary.main,
			disabled: colors.secondary.main,
		},
		action: {
			disabled:
				mode === 'dark'
					? colors.secondary.dark
					: colors.secondary.light,
		},
		divider:
			mode === 'dark' ? colors.secondary.dark : colors.secondary.light,
	};
};

export default Palette;
