import MainLayout from 'layout/MainLayout';

import { getRoutes } from './router';

const Routes = {
	path: '/',
	element: <MainLayout />,
	children: getRoutes([
		{
			path: '',
		},
		{
			path: 'dashboard/approvals',
			children: [
				{
					path: 'dashboard/approvals/transactions',
					context: ['new', 'edit'],
					list: true,
				},
				{
					path: 'dashboard/approvals/orders',
					context: ['new', 'edit'],
					list: true,
				},
			],
		},
	]),
};

export default Routes;
