import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { APP_BASE_NAME } from 'config';
import App from 'App';

import 'assets/css/style.scss';

const root = createRoot(document.getElementById('root'));
root.render(
	<StrictMode>
		<BrowserRouter basename={APP_BASE_NAME}>
			<App />
		</BrowserRouter>
	</StrictMode>,
);
