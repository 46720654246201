import MainLayout from 'layout/MainLayout';

import { getRoutes } from './router';

const routes = [
	{
		path: 'settings',
		children: [
			{
				path: 'settings/general',
			},
			{
				path: 'settings/permission',
			},
			{
				path: 'settings/database',
			},
			{
				path: 'settings/account',
			},
		],
	},
];

const Data = {
	path: '/',
	element: <MainLayout />,
	children: getRoutes(routes, true),
};

export default Data;
