import MainLayout from 'layout/MainLayout';

import { getRoutes } from './router';

const routes = [
	{
		path: 'expenses',
	},
	{
		path: 'expenses/expenditure',
		children: [
			{
				path: 'expenses/expenditure/expense',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'expenses/expenditure/pay-tax',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'expenses/expenditure/payable',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'expenses/expenditure/payout',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'expenses/expenditure/return-tax',
				context: ['new', 'edit'],
				list: true,
			},
		],
	},
	{
		path: 'expenses/purchases',
		children: [
			{
				path: 'expenses/purchases/orders',
				context: ['new', 'edit'],
				list: true,
			},
			{
				path: 'expenses/purchases/stores',
				context: ['new', 'edit'],
				list: true,
			},
		],
	},
	{
		path: 'expenses/inventory',
		children: [
			{
				path: 'expenses/inventory/items',
				context: ['new', 'edit'],
				list: true,
			},
		],
	},
];

const Expenses = {
	path: '/',
	element: <MainLayout />,
	children: getRoutes(routes, true),
};

export default Expenses;
