export default function Select() {
	return {
		MuiSelect: {
			defaultProps: {
				size: 'small',
			},
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.disabled && {
						'& .MuiSelect-select.Mui-disabled': {
							paddingRight: 14,
						},
						'& .MuiSvgIcon-root': {
							display: 'none',
						},
					}),
				}),
			},
		},
	};
}
