export default function FormGroup() {
	return {
		MuiFormGroup: {
			styleOverrides: {
				root: ({ ownerState }) => ({
					...(ownerState.row !== true && {
						alignItems: 'flex-start',
					}),
				}),
			},
		},
	};
}
