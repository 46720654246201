import { useContext, createContext, useState } from 'react';

const NavigationContext = createContext();

const NavigationProvider = ({ children }) => {
	const [selected, setSelected] = useState({});

	const [open, setOpen] = useState(false);

	return (
		<NavigationContext.Provider
			value={{ open, selected, setOpen, setSelected }}
		>
			{children}
		</NavigationContext.Provider>
	);
};

export default NavigationProvider;

export const useNavigation = () => {
	return useContext(NavigationContext);
};
