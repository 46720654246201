import dayjs from 'dayjs';
import thTH from 'dayjs/locale/th';
import buddhistEra from 'dayjs/plugin/buddhistEra';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

thTH.formats = Object.assign({}, thTH.formats, {
	L: 'DD/MM/BBBB',
	LL: 'D MMMM BBBB',
	LLL: 'D MMMM BBBB เวลา H:mm',
	LLLL: 'วันddddที่ D MMMM BBBB เวลา H:mm',
});

thTH.weekStart = 1;

dayjs.locale(thTH, null, true);

dayjs.extend(buddhistEra);

export default class DayjsAdapter extends AdapterDayjs {
	constructor({ locale, formats, instance }) {
		super({ locale, formats, instance });
		Object.assign(this.formatTokenMap, {
			BB: 'year',
			BBBB: { sectionType: 'year', contentType: 'digit', maxLength: 4 },
		});
	}
}
