import api from './api';

import { getLocalRefreshToken, saveAuthToken, clearAuthenToken } from './token';

export const login = async ({ saveAuth, ...data }) => {
	try {
		const response = await api.post('/users/login', data);

		const { error, accessToken, refreshToken, expiresIn } = response.data;

		if (!error && accessToken && refreshToken) {
			saveAuthToken({ accessToken, refreshToken, expiresIn }, saveAuth);
		}
		return response;
	} catch (error) {
		throw error;
	}
};

export const logout = async (user) => {
	try {
		if (user) {
			const response = await api.post('/users/logout');

			clearAuthenToken();

			return response;
		}

		clearAuthenToken();
		Promise.resolve({ success: true });
	} catch (error) {
		clearAuthenToken();
		throw error;
	}
};

export const register = async (data) => {
	try {
		const response = await api.post('/users/register', data);
		return response;
	} catch (error) {
		throw error;
	}
};

export const getProfile = async (id) => {
	try {
		const response = await api.get(`/users/${id}`);
		return response;
	} catch (error) {
		throw error;
	}
};

export const getRefreshToken = async (siteId = null) => {
	const token = getLocalRefreshToken();
	if (!token) {
		throw new Error('Invalid refresh token');
	}

	try {
		const response = await api.post('/users/refresh', {
			siteId: siteId,
			refreshToken: token,
		});

		const { error, accessToken, refreshToken, expiresIn } = response.data;

		if (!error && accessToken && refreshToken) {
			saveAuthToken({ accessToken, refreshToken, expiresIn });
		}

		return response;
	} catch (error) {
		throw error;
	}
};
