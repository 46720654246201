import MainLayout from 'layout/MainLayout';

import { getRoutes } from './router';

const routes = [
	{
		path: 'products',
		context: ['new', 'edit'],
		list: true,
	},
	{
		path: 'users',
		context: ['new', 'edit'],
		list: true,
	},
	{
		path: 'sites',
		context: ['new', 'edit'],
		list: true,
	},
];

const Data = {
	path: '/',
	element: <MainLayout />,
	children: getRoutes(routes, true),
};

export default Data;
