import axios from 'axios';

import { APP_API_URI } from 'config';

import { getLocalAccessToken } from './token';

const api = axios.create({
	baseURL: APP_API_URI,
});

api.interceptors.request.use((config) => {
	const token = getLocalAccessToken();
	if (token) {
		config.headers.Authorization = `Bearer ${token}`;
	}

	return config;
});

export default api;
