import Autocomplete from './Autocomplete';
import Button from './Button';
import CardContent from './CardContent';
import DialogTitle from './DialogTitle';
import FormControl from './FormControl';
import FormGroup from './FormGroup';
import InputBase from './InputBase';
import Select from './Select';
import Stack from './Stack';
import TextField from './TextField';

export default function ComponentsOverrides(theme) {
	return Object.assign(
		{},
		Autocomplete(),
		Button(theme),
		CardContent(),
		DialogTitle(),
		FormControl(),
		FormGroup(),
		InputBase(),
		Select(),
		Stack(),
		TextField(),
	);
}
