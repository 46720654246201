export function getItem(name, defaultValue = null) {
	return window.localStorage.getItem(name) || defaultValue;
}

export function setItem(name, value) {
	return window.localStorage.setItem(name, value);
}

export function removeItem(name) {
	return window.localStorage.removeItem(name);
}

export function getSession(name, defaultValue = null) {
	return window.sessionStorage.getItem(name) || defaultValue;
}

export function setSession(name, value) {
	return window.sessionStorage.setItem(name, value);
}

export function removeSession(name) {
	return window.sessionStorage.removeItem(name);
}
