import PropTypes from 'prop-types';
import { useMemo } from 'react';

import { createTheme, ThemeProvider } from '@mui/material/styles';

import CssBaseline from '@mui/material/CssBaseline';
import StyledEngineProvider from '@mui/material/StyledEngineProvider';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';

import { PickerLocaleText } from 'utils/locale';

import DayjsAdapter from './DayjsAdapter';
import Palette from './palette';
import Typography from './typography';
import Shadows from './shadows';
import componentsOverride from './overrides';

export default function ThemeCustomization({ children }) {
	const palette = Palette('light');
	const typography = Typography({ fontFamily: 'Sarabun' });
	const shadows = useMemo(() => Shadows(palette), [palette]);

	const themeOptions = useMemo(
		() => ({
			breakpoints: {
				values: {
					xs: 0,
					sm: 768,
					md: 1024,
					lg: 1266,
					xl: 1440,
				},
			},
			customShadows: shadows,
			direction: 'ltr',
			palette,
			shape: {
				borderRadius: 8,
			},
			typography,
		}),
		[palette, typography, shadows],
	);

	const theme = createTheme(themeOptions);
	theme.components = componentsOverride(theme);

	return (
		<LocalizationProvider
			adapterLocale="th"
			dateAdapter={DayjsAdapter}
			dateFormats={{
				year: 'BBBB',
				monthAndYear: 'MMMM BBBB',
			}}
			localeText={PickerLocaleText}
		>
			<StyledEngineProvider injectFirst>
				<ThemeProvider theme={theme}>
					<CssBaseline />
					{children}
				</ThemeProvider>
			</StyledEngineProvider>
		</LocalizationProvider>
	);
}

ThemeCustomization.propTypes = {
	children: PropTypes.node,
};
