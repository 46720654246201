import { lazy } from 'react';

import Loadable from 'components/Loadable';

export const ROUTE_PATHS_REGEX = /\/(edit|new|view|list|delete).*/;

export function unTrailingslash(url = '', replace = '') {
	return url.replace(/\/$/, replace);
}

export function trailingslash(url = '', replace = '/') {
	return unTrailingslash(url, replace);
}

export function getRoutes(items) {
	const routes = [];

	const generateRoutes = (item) => {
		const path = unTrailingslash(item.path);

		const Index = Loadable(lazy(() => import(`modules/${path}`)));

		routes.push({
			path: trailingslash(path),
			element: <Index />,
		});

		if (item.context && item.context.length > 0) {
			const View = Loadable(lazy(() => import(`modules/${path}/view`)));
			item.context.forEach((mode) => {
				routes.push({
					path: `${path}/${mode.toString().toLowerCase()}/:id?`,
					element: <View context={mode.toLowerCase()} />,
				});
			});
		}

		if (item.list === true) {
			const List = Loadable(lazy(() => import(`modules/${path}/list`)));

			routes.push({
				path: `${path}/list/:id?`,
				element: <List />,
			});
		}

		if (item.children && item.children.length > 0) {
			item.children.forEach((child) => {
				generateRoutes(child);
			});
		}
	};

	items.forEach((item) => {
		generateRoutes(item);
	});

	return routes;
}
