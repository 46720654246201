import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MuiBreadcrumbs from '@mui/material/Breadcrumbs';

import HomeIcon from '@mui/icons-material/Home';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { APP_DEFAULT_PATH } from 'config';

const Breadcrumbs = ({ navigation }) => {
	const location = useLocation();

	let customLocation = location.pathname;

	const [main, setMain] = useState();
	const [item, setItem] = useState();

	// Set active item state.
	const populateItems = (menu) => {
		if (menu.children) {
			menu.children.filter((item) => {
				if (item.type && item.type === 'collapse') {
					if (customLocation?.includes(item.url)) {
						setMain(item);
					}
					populateItems(item);
				} else if (item.type && item.type === 'item') {
					if (customLocation?.includes(item.url)) {
						setMain(menu);
						setItem(item);
					}
				}
				return false;
			});
		}
	};

	useEffect(() => {
		navigation.items?.map((item) => {
			if (item.type && item.type === 'group') {
				populateItems(item);
			}
			return false;
		});
	});

	let mainContent;
	let itemContent;

	// Item.
	if (item && item.type === 'item' && item.breadcrumbs !== false) {
		itemContent = (
			<Typography color="secondary" variant="h1">
				{item.title}
			</Typography>
		);
	}

	// Collapse item.
	if (main && main.type === 'collapse') {
		mainContent = (
			<Typography
				color="secondary"
				variant="h6"
				component={Link}
				to={main.url}
				sx={{
					display: 'flex',
					textDecoration: 'none',
					alignContent: 'center',
					alignItems: 'center',
				}}
			>
				{main.title}
			</Typography>
		);
	}

	return (
		<Stack
			direction={{ xs: 'column', sm: 'row' }}
			alignItems="center"
			justifyContent="space-between"
			spacing={2}
			sx={{ mb: 2 }}
		>
			{itemContent}
			{}
			<MuiBreadcrumbs
				aria-label="breadcrumb"
				separator={<ArrowForwardIosIcon fontSize="10px" />}
				color="secondary"
			>
				<Typography
					color="secondary"
					component={Link}
					to={APP_DEFAULT_PATH}
					variant="h6"
					sx={{
						textDecoration: 'none',
						display: 'flex',
						alignItems: 'center',
					}}
				>
					<HomeIcon fontSize="small" />
				</Typography>
				{mainContent}
			</MuiBreadcrumbs>
		</Stack>
	);
};

Breadcrumbs.propTypes = {
	navigation: PropTypes.object,
	children: PropTypes.node,
};

export default Breadcrumbs;
